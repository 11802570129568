/**
 * Individual image page.
 */

import React from "react"
import {graphql, Link} from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";
import Img from "gatsby-image";

import JSONData from "../content/images"

const ImageTemplate = ({data, location}) => {

    const siteTitle = data.site.siteMetadata?.title || `Title`;
    const imageNode = data.image.edges[0].node;

    const {previous, next, random, quote, music} = data;

    const imageList = {};
    for (const imageContent of JSONData.content) {
        imageList[imageContent.file] = imageContent;
    }

    const imageData = imageList[imageNode.base];

    const imageTitle = imageData.title;

    let previousPath = '';
    if (previous.edges.length) {
        previousPath = '/' + previous.edges[0].node.relativePath;
    }

    let nextPath = '';
    if (next.edges.length) {
        nextPath = '/' + next.edges[0].node.relativePath;
    }

    const randomPath = '/' + random.edges[0].node.relativePath;

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title={imageTitle}
            />

            <Sidebar quote={quote} music={music}/>

            <div className="main-content">
                <article className="image content">
                    {imageTitle && (<h1 className="element-invisible">{imageTitle}</h1>)}
                    <Img alt={imageTitle} fluid={imageNode.childImageSharp.fluid} />
                </article>

                <nav className="blog-post-nav">
                    <ul className="prev-next">
                        {previousPath && (
                            <li>
                                <Link to={previousPath} rel="prev" className="link-previous prev-next-link">
                                    Previous
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link to={randomPath} className="link-random prev-next-link">Random</Link>
                        </li>
                        {nextPath && (
                            <li>
                                <Link to={nextPath} rel="next" className="link-next prev-next-link">
                                    Next
                                </Link>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
        </Layout>
    )
};

export default ImageTemplate;


export const pageQuery = graphql`
query ImageById(
    $id: String
    $previousPostId: String
    $nextPostId: String
    $randomId: String
    $quoteId: String
    $musicId: String
) {
    site {
        siteMetadata {
            title
        }
    }
    quote: markdownRemark(id: { eq: $quoteId }) {
        id
        html
        frontmatter {
            title
            author
            source
            source_link
        }
    }
    music: markdownRemark(id: { eq: $musicId }) {
        id
        frontmatter {
          title
          slug
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        html
    }
    image: allFile(filter: {id: {eq: $id}}) {
        edges {
          node {
            id
            publicURL
            relativePath
            base
            childImageSharp {
                fluid(
                    maxWidth: 1000
                ) { ...GatsbyImageSharpFluid_tracedSVG }
            }
          }
        }
    }
    previous: allFile(filter: {id: {eq: $previousPostId}}) {
        edges {
          node {
            id
            relativePath
          }
        }
    }
    next: allFile(filter: {id: {eq: $nextPostId}}) {
        edges {
          node {
            id
            relativePath
          }
        }
    }
    random: allFile(filter: {id: {eq: $randomId}}) {
        edges {
          node {
            id
            relativePath
          }
        }
    }
}
`;
